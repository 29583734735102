import React from 'react';
import { Link } from 'react-router-dom';
import './PossibleRocks.css';

const PossibleRocks = () => {
	return (
		<div className='PossibleContainer'>
			<h2 className='Subheader'>What can you get?</h2>
			<p className="Text BigText">
			There are over 80 million possible combinations of attributes for a DigiRock! Some of them are {' '}
			<strong>EXTREMELY</strong> rare, and we've highlighted a few below:
			</p>
			<div className='ImageBox ImageMarginBottom'>
        <div className="PosImageContainer">
          <a target="_blank" rel="noreferrer" href='https://opensea.io/collection/digirocks'>
            <img className="PosImage" src='/gifs/moon-rock.gif' alt='moon'/>
          </a>
          <div className="ImageDescription">
          	<div><strong>Moon Rock</strong></div>
          	<div><i>only 10 available</i></div>
          </div>
        </div>
        <div className="PosImageContainer">
          <a target="_blank" rel="noreferrer" href='https://opensea.io/collection/digirocks'>
            <img className="PosImage" src='/gifs/obsidian-rock.gif' alt='obsidian'/>
          </a>
          <div className="ImageDescription">
          	<div><strong>Obsidian Rock</strong></div>
          	<div><i>only 49 available</i></div>
          </div>
        </div>
        <div className="PosImageContainer">
          <a target="_blank" rel="noreferrer" href='https://opensea.io/collection/digirocks'>
            <img className="PosImage" src='/gifs/gold-rock.gif' alt='gold'/>
          </a>
          <div className="ImageDescription">
          	<div><strong>Gold Rock</strong></div>
          	<div><i>only 113 available</i></div>
          </div>
        </div>
        <div className="PosImageContainer">
          <a target="_blank" rel="noreferrer" href='https://opensea.io/collection/digirocks'>
            <img className="PosImage" src='/gifs/glacier-rock.gif' alt='glacier'/>
          </a>
          <div className="ImageDescription">
          	<div><strong>Glacier Rock</strong></div>
          	<div><i>only 493 available</i></div>
          </div>
        </div>
      </div>
      <div className='ImageBox ImageMarginBottom'>
        <div className="PosImageContainer">
          <a target="_blank" rel="noreferrer" href='https://opensea.io/collection/digirocks'>
            <img className="PosImage" src='/gifs/dragon-wings.gif' alt='dragon wings'/>
          </a>
          <div className="ImageDescription">
          	<div><strong>Dragon Wings</strong></div>
          	<div><i>only 14 available</i></div>
          </div>
        </div>
        <div className="PosImageContainer">
          <a target="_blank" rel="noreferrer" href='https://opensea.io/collection/digirocks'>
            <img className="PosImage" src='/gifs/mech-suit.gif' alt='mech suit'/>
          </a>
          <div className="ImageDescription">
          	<div><strong>Mech Suit</strong></div>
          	<div><i>only 13 available</i></div>
          </div>
        </div>
        <div className="PosImageContainer">
          <a target="_blank" rel="noreferrer" href='https://opensea.io/collection/digirocks'>
            <img className="PosImage" src='/gifs/pet-fish.gif' alt='pet fish'/>
          </a>
          <div className="ImageDescription">
          	<div><strong>Pet Fish</strong></div>
          	<div><i>only 246 available</i></div>
          </div>
        </div>
        <div className="PosImageContainer">
          <a target="_blank" rel="noreferrer" href='https://opensea.io/collection/digirocks'>
            <img className="PosImage" src='/gifs/pet-doggie.gif' alt='pet doggie'/>
          </a>
          <div className="ImageDescription">
          	<div><strong>Pet Doggy</strong></div>
          	<div><i>only 497 available</i></div>
          </div>
        </div>
      </div>
      <p className="BigText CenterText">
        <Link to='/attributes' className='WhiteLink'>
          Click here for the full list of attributes >
        </Link>
			</p>
		</div>
	);
}

export default PossibleRocks;
