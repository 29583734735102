import React, { useState, useEffect } from 'react';
import MetaMaskConnect from './MetaMaskConnect';
import { digirocks_abi, digirocks_contract, max_rocks } from './constants';
import Web3 from 'web3';
import './PurchaseRocks.css';


const PurchaseRocks = () => {  
	const [connectedAccount, setConnectedAccount] = useState(null);
	// Contract state
	const [hasSaleStarted, setHasSaleStarted] = useState(false);
	const [currentPrice, setCurrentPrice] = useState(0);
	const [amountRemaining, setAmountRemaining] = useState(0);
	const [freeAvailable, setFreeAvailable] = useState(0)
	// Form state
	const [quantity, setQuantity] = useState(1);
	const [claimQuantity, setClaimQuantity] = useState(1);
	const [claiming, setClaiming] = useState(false);
	const [ownedRocks, setOwnedRocks] = useState([]);

	const openSeaUrlPrefix = `https://opensea.io/assets/${digirocks_contract}/`;

	useEffect(() => {
		if (window.ethereum) {
			const loadContract = async () => {
		  	window.contract = await new window.web3.eth.Contract(digirocks_abi, digirocks_contract);
		  	// Do a whole bunch of initialization here
		  	const saleStarted = await window.contract.methods.hasSaleStarted().call();
		  	setHasSaleStarted(saleStarted);
		  	// Don't run the next two if we have no sale yet
		  	if (saleStarted) {
			  	const price = await window.contract.methods.calculatePrice().call();
					setCurrentPrice(price);
					const supply = await window.contract.methods.totalSupply().call();
					setAmountRemaining(max_rocks-supply);
					fetchOwned();
		  	}
			}
			window.web3 = new Web3(window.ethereum);
			loadContract();
		}
	});

	useEffect(() => {
		if (!connectedAccount && window.ethereum) {
			setConnectedAccount(window.ethereum.selectedAddress);
		}
	}, [connectedAccount, setConnectedAccount]);

	useEffect(() => {
		if (connectedAccount && window.contract) {
			const loadAccountData = async () => {
				const claimable = await window.contract.methods.redemptions(window.ethereum.selectedAddress).call();
				const claimableInt = parseInt(claimable || '0', 10);
				setFreeAvailable(claimableInt);
				setClaimQuantity(claimableInt);
			}
			loadAccountData()
		}
	}, [connectedAccount]);

	if (!window.ethereum) {
		return (
			<div className='Container'>
				<div className='HeaderSections'>
					<div className='Section'>
						<MetaMaskConnect 
							connectedAccount={connectedAccount}
							setConnectedAccount={setConnectedAccount}
						/>
					</div>
				</div>
			</div>
		)
	}

	window.ethereum.on('accountsChanged', function (accounts) {
    // Time to reload your interface with accounts[0]!
    setConnectedAccount(accounts[0]);
  });

  const makePurchase = async () => {
  	const price = await window.contract.methods.calculatePrice().call();
		const res = await window.contract.methods.adoptDigirock(quantity).send({ 
			from: connectedAccount,
			value: price * quantity,
		});
		return res;
  }

  const makeFreeClaim = async () => {
  	setClaiming(true);
  	return await window.contract.methods.claimFreeDigirock(claimQuantity).send({
  		from: connectedAccount,
  	})
  }

  const fetchOwned = async () => {
  	if (connectedAccount) {
  		var owned = await window.contract.methods.tokensOfOwner(connectedAccount).call()
	  	setOwnedRocks(owned);
  	}
  }

	const renderStatusMessage = () => {
		if (!!window.contract) {
			if (hasSaleStarted) {
				return <div>DigiRocks Remaining: <strong>{amountRemaining}</strong></div>;
			} else {
				return <div>The sale has not started yet</div>;
			}
		}

		return <div>Connect your wallet!</div>
	}

	return (
		<div>
      <h2 className="Subheader">
      	<div className='PurchaseHeader'>

        	<div>Purchase</div>
        	{
        		!!currentPrice && (
	        		<div className='CurrentPrice'>
	        			Current Price Ξ {Web3.utils.fromWei(currentPrice)}
	        		</div>	
        		)
        	}
        	
        </div>
      </h2>
			<div className='Container'>
				<div className='HeaderSections'>
					<div className='Section LeftAlign'>
						<MetaMaskConnect 
							connectedAccount={connectedAccount}
							setConnectedAccount={setConnectedAccount}
						/>
					</div>
					<div className='Section RightAlign LargeText'>
						<div className='Section'>
							{ !hasSaleStarted && renderStatusMessage() }
							{
								(hasSaleStarted && !!window.contract && (
									<div>
										<div>
											<input 
												className="Input QuantityInput"
												value={quantity}
												type="number"
												min="1"
												max="20"
												onChange={(event) => {
													var amount = event.target.value;
													if (amount > 20) {
														amount = 20;
													}
													if (amount < 0) {
														amount = 1;
													}
													setQuantity(amount);
												}}
											/>
											<button
												className='Button'
												onClick={makePurchase}
												disabled={!connectedAccount}
											>
												{!!connectedAccount ? <span>Purchase</span> : <span>Please connect your account</span>}
											</button>
										</div>
									</div>
								))
							}
						</div>
					</div>
				</div>
			</div>
			{
				!!ownedRocks.length && (<div className='OwnedContainer'>
					<div className='LeftAlign Section'>
						Owned Rocks ({ownedRocks.length})
					</div>
					<div className='LeftAlign Section MarginTop Overflow'>
						{
							ownedRocks.map((value, index) => 
								<span key={index}>
									<a target="_blank" rel="noreferrer" href={`${openSeaUrlPrefix}${value}`}>
										{value}
									</a>
									{
										index < ownedRocks.length-1 && (
											<span>
												,&nbsp;
											</span>
										)
									}
								</span>
							)
						}
					</div>
				</div>)
			}
		</div>
	)
}

export default PurchaseRocks;
