import axios from 'axios';

const salesDataURL = 'https://niftyriver.herokuapp.com/api/platforms/history/sales/opensea/';
const mintsDataURL = 'https://niftyriver.herokuapp.com/api/platforms/history/mints/opensea/';

export const getSalesData = async () => {
	const response = await axios.get(salesDataURL);
	return response.data;
}

export const getMintsData = async () => {
	const response = await axios.get(mintsDataURL);
	return response.data;
}
