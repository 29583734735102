import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import Header from './common/header';
import routes from './routes';


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Header />
      {routes}
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
