import React, { useState, useEffect } from 'react';
import MetaMaskOnboarding from '@metamask/onboarding';
import './PurchaseRocks.css'; 

const MetaMaskConnect = ({connectedAccount, setConnectedAccount}) => {
  const [accountDisplay, setAccountDisplay] = useState(null);
  const [isInstalled, setIsInstalled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const forwarderOrigin = window.location;
  const onboarding = new MetaMaskOnboarding({ forwarderOrigin });

	const isMetaMaskInstalled = () => {
    //Have to check the ethereum binding on the window object to see if it's installed
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  };

	useEffect(() => {
		if (!isMetaMaskInstalled()) {
      //If it isn't installed we ask the user to click to install it
      setIsInstalled(false);
    } else {
      //If it is installed we change our button text
      setIsInstalled(true);
    }
	}, [setIsInstalled]);

  const onClickInstall = () => {
    setIsDisabled(true);
    onboarding.startOnboarding();
  }

  const onClickConnect = async () => {
    setIsDisabled(true);
    try {
      // Will open the MetaMask UI
      // You should disable this button while the request is pending!
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      if (accounts.length > 0) {
        const fullAccount = accounts[0];
        setAccountDisplay(
          `${fullAccount.substring(0, 6)}...${fullAccount.substring(fullAccount.length-4, fullAccount.length)}`
        )
        setConnectedAccount(fullAccount);
      } else {
        setIsDisabled(false);
      }
    } catch (error) {
      setIsDisabled(false);
      console.error(error);
    }
  };

	return (
    <button
      className='Button'
    	id="connectButton"
      onClick={isInstalled ? onClickConnect : onClickInstall}
      disabled={isDisabled}
    >
    	{accountDisplay || (isInstalled ? 'Connect' : 'Click here to install MetaMask!')}
    </button>
	);
}

export default MetaMaskConnect;
