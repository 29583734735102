import React from 'react';
import './Attributes.css';


const Attributes = () => {

	const bodies = [
		{name: 'Shale', count: 5744},
		{name: 'Sandstone', count: 3116},
		{name: 'Glacier', count: 493},
		{name: 'Magma', count: 475},
		{name: 'Gold', count: 113},
		{name: 'Obsidian', count: 49},
		{name: 'Moon', count: 10},
	]

	const backgrounds = [
		{name: 'Red', count: 1034},
		{name: 'Purple', count: 1032},
		{name: 'Sky Blue', count: 1017},
		{name: 'Yellow', count: 1011},
		{name: 'Olive', count: 1001},
		{name: 'Green', count: 996},
		{name: 'Orange', count: 990},
		{name: 'Teal', count: 985},
		{name: 'Blue Gray', count: 982},
		{name: 'Pink', count: 952},
	]

	const attributes = [
		{name: 'Mustache', count: 1148},
		{name: 'Red Lipstick', count: 1121},
		{name: 'Crew Cut', count: 834},
		{name: 'Bob', count: 782},
		{name: 'Black Glasses', count: 761},
		{name: 'Sunglasses', count: 748},
		{name: 'Eye Patch', count: 744},
		{name: 'Fools Glasses', count: 729},
		{name: 'Pink Party Glasses', count: 714},
		{name: 'Blue Eyes', count: 705},
		{name: 'Ninja Eyes', count: 700},
		{name: 'Brown Hat', count: 606},
		{name: 'Purple Hat', count: 593},
		{name: 'Whistle', count: 569},
		{name: 'Piercings', count: 563},
		{name: 'Pet Doggy', count: 497},
		{name: 'Axe', count: 465},
		{name: 'Giant Ice Cream', count: 463},
		{name: 'Hook Hand', count: 455},
		{name: 'Meat Cleaver', count: 452},
		{name: 'Water Gun', count: 449},
		{name: 'Pony Tail', count: 444},
		{name: 'Drink Hat', count: 438},
		{name: 'Toupee', count: 437},
		{name: 'Snails', count: 380},
		{name: 'Gold Chain', count: 362},
		{name: 'Painting Palette', count: 349},
		{name: 'Plaid Apron', count: 326},
		{name: 'Headphones', count: 322},
		{name: 'Slime Mouth', count: 314},
		{name: 'Deputy Star', count: 308},
		{name: 'Pipe', count: 299},
		{name: 'Mohawk', count: 297},
		{name: 'Wizard Hat', count: 295},
		{name: 'Car', count: 267},
		{name: 'War Hammer', count: 260},
		{name: 'Violin', count: 254},
		{name: 'Wings', count: 253},
		{name: 'Pet Fish', count: 246},
		{name: 'Lunch Box', count: 242},
		{name: 'Raccoon Eyes', count: 242},
		{name: 'Bag Of Money', count: 240},
		{name: 'Vampire', count: 236},
		{name: 'Alien Eyes', count: 229},
		{name: 'Scythe', count: 221},
		{name: 'Mardi Gras Mask', count: 219},
		{name: 'Beer Bottle', count: 218},
		{name: 'Pet Cactus', count: 211},
		{name: 'Cyclops', count: 209},
		{name: 'Ice Eyes', count: 206},
		{name: 'Jet Pack', count: 189},
		{name: 'Flamingo Floatie', count: 174},
		{name: 'Chef Hat', count: 165},
		{name: 'Corn Rows', count: 157},
		{name: 'Cowboy Hat', count: 154},
		{name: 'Artist Hat', count: 153},
		{name: 'Top Hat', count: 147},
		{name: 'Hot Dog', count: 140},
		{name: 'Horror Mask', count: 132},
		{name: 'Electric Guitar', count: 132},
		{name: 'Magic Wand', count: 129},
		{name: 'Bulldog Mouth', count: 128},
		{name: 'Watermelon', count: 128},
		{name: 'Pet Parrot', count: 127},
		{name: 'Jewelers Glasses', count: 122},
		{name: 'Revolver', count: 118},
		{name: 'Macaroni Hair', count: 112},
		{name: 'Laser Eyes', count: 111},
		{name: 'Laser Gun', count: 111},
		{name: 'Bird Mask', count: 108},
		{name: 'Cage', count: 106},
		{name: 'Ape', count: 105},
		{name: 'Kiddie Pool', count: 104},
		{name: 'Balancing Rocks', count: 98},
		{name: 'Viking Helmet', count: 95},
		{name: 'Garden Hat', count: 90},
		{name: 'Magic Carpet', count: 87},
		{name: 'Kebab', count: 77},
		{name: 'Tree', count: 66},
		{name: 'American Flag', count: 45},
		{name: 'Flame Sword', count: 42},
		{name: 'Pirate Ship', count: 30},
		{name: 'Rocket Launcher', count: 28},
		{name: 'All Seeing Hand', count: 25},
		{name: 'Spectrum Goggles', count: 15},
		{name: 'Vent Mask', count: 14},
		{name: 'Dragon Wings', count: 14},
		{name: 'Mech Suit', count: 13},
		{name: 'Cyborg Eyes', count: 13},
		{name: 'King Cobra', count: 7},
	]

	return (
		<div className='App'>
			<h2 className="Subheader">
        Attributes
      </h2>
			<table className='AttributeText'>
				<tr>
					<th>
						Name
					</th>
					<th>
						Count
					</th>
				</tr>
				<tr>
					<td colspan='2'><strong>Body</strong></td>
				</tr>
				{
					bodies.map(row => {
						return (
							<tr>
								<td>{row.name}</td>
								<td>{row.count}</td>
							</tr>
						)
					})
				}
				<tr>
					<td colspan='2'><strong>Attributes</strong></td>
				</tr>
				{
					attributes.map(row => {
						return (
							<tr>
								<td>{row.name}</td>
								<td>{row.count}</td>
							</tr>
						)
					})
				}
				<tr>
					<td colspan='2'><strong>Background</strong></td>
				</tr>
				{
					backgrounds.map(row => {
						return (
							<tr>
								<td>{row.name}</td>
								<td>{row.count}</td>
							</tr>
						)
					})
				}
			</table>
		</div>
	);
}

export default Attributes;
