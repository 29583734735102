import React from 'react';
import './header.css';


const Header = () => {
  const niftyRiverURL = 'https://www.niftyriver.io/';

	return (
		<div className="App">
      <div className="TopBar">
        <div className="HeaderContainer">
          <h1 className="Header">
            <a className="LogoLink" href='/'>
              DigiRocks
            </a>
          </h1>
        </div>
        <div className="BottomSide">
          <p className="Text Socials">
            <a className="SocialItem" target="_blank" rel="noreferrer" href={niftyRiverURL}>
              <img className="SocialImage" src='/logos/niftyriver-logo.png' alt='NiftyRiver'/>
            </a>
            <a className="SocialItem" target="_blank" rel="noreferrer" href='https://twitter.com/digirocksnft'>
              <img className="SocialImage" src='/social/twitter.png' alt='Twitter'/>
            </a>
            <a className="SocialItem" target="_blank" rel="noreferrer" href='https://discord.com/invite/MEKYCUwT2j'>
              <img className="SocialImage" src='/social/discord.jpeg' alt='Discord'/>
            </a>
            <a className="SocialItem" target="_blank" rel="noreferrer" href='https://opensea.io/collection/digirocks'>
              <img className="SocialImage" src='/social/opensea.png' alt='OpenSea'/>
            </a>
          </p>
        </div>
      </div>
    </div>
	);
}

export default Header;
