import React from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import App from './App';
import Attributes from './Attributes';
import MarketTracker from './market/components/MarketTracker';


export default (
	<Switch>
		<Route
			path='/attributes'
			key='/attributes'
			component={Attributes}
		/>
		<Route
			path='/market-tracker'
			key='/market-tracker'
			component={MarketTracker}
		/>
		<Route
			path='/'
			component={App}
		/>
	</Switch>
);
