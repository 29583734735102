import React from 'react';
import './Table.css';
import { useTable } from 'react-table';

const Table = ({ columns, data, onClickRow=null, renderRowSubComponent=()=>null }) => {
	const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  });

  const isClickable = onClickRow !== null;
  const safeOnClickRow = isClickable ? onClickRow : () => {};

  return (
  	<table className="tableTable" {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th 
              	className="tableHeader" 
              	{...column.getHeaderProps({
              		style: { minWidth: column.minWidth, maxWidth: column.maxWidth, width: column.width}
              	})}
              >
              		{column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <React.Fragment key={i}>
              <tr 
              	className={isClickable ? "tableRow clickableTableRow" : "tableRow"} 
              	{...row.getRowProps()}
              	onClick={() => safeOnClickRow(row.original, i)}
              >
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
              {renderRowSubComponent(row)}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;
