import React from 'react';
import './SafeImage.css';

const getRandomRockImage = () => {
	const items = [
		'/gifs/gif-1.gif',
		'/gifs/gif-101.gif',
		'/gifs/gif-103.gif',
		'/gifs/gif-17.gif',
		'/gifs/gif-37.gif',
		'/gifs/gif-65.gif',
	]
	return items[Math.floor(Math.random() * items.length)]
}

const SafeImage = ({ url, alt }) => {

	return (
		<object className='LogoImage' data={url || getRandomRockImage()} type="image/png">
			<img 
				className='LogoImage'
				src={getRandomRockImage()}
				alt={alt}
			/>
		</object>
	);
}

export default SafeImage;
