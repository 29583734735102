import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { getSalesData, getMintsData } from '../hooks';
import Table from '../../common/Table';
import SafeImage from '../../common/SafeImage';
import '../css/MarketTracker.css';

export const timeAgoFromString = (string) => {
	return moment.utc(string).fromNow()
}

const floatToDollar = (float) => {
	return `$${float.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`;
}

const clipCollectionName = (name) => {
	const maxLength = 35;
	let resultString = name;
	if (name.length > maxLength) {
		resultString = `${name.substring(0, maxLength)}...`
	}
	return resultString;
}

const MarketTracker = () => {
	const [view, setView] = useState('sales');
	const [salesData, setSalesData] = useState();
	const [mintsData, setMintsData] = useState();
	const [isSalesLoading, setIsSalesLoading] = useState(true);
	const [isMintsLoading, setIsMintsLoading] = useState(true);

	// Fetch the market data from our API
	useEffect(() => {
		getSalesData().then(
			data => {
				setSalesData(data);
				setIsSalesLoading(false);
			}
		)

		getMintsData().then(
			data => {
				setMintsData(data);
				setIsMintsLoading(false);
			}
		)
	}, [])

	const salesColumns = useMemo(
		() => [
			{
				Header: '',
				id: 'image',
				accessor: d =>  (
					<SafeImage
						url={d.collection_image}
						alt={d.collection_name}
					/>
				),
				maxWidth: 50,
			},
			{
				Header: 'Name',
				width: 200,
				accessor: d => (
					<div className='collectionName'>
						{
							d.is_new && (
								<span className='newLabel'>
									New
								</span>
							)
						}
						{clipCollectionName(d.collection_name)}
					</div>
				),
			},
			{
				Header: 'Sales',
				accessor: 'count',
				minWidth: 100,
			},
			{
				Header: 'Unique Buyers',
				accessor: 'unique_count',
				minWidth: 100,
			},
			{
				Header: 'New Buyers',
				accessor: 'new_buyers',
				minWidth: 100,
			},
			{
				Header: 'Estimated Dollars',
				accessor: d => floatToDollar(d.price.total_usd),
				minWidth: 100,
			}
		],
		[]
	);

	const mintColumns = useMemo(
		() => [
			{
				Header: '',
				id: 'image',
				accessor: d =>  (
					<SafeImage
						url={d.collection_image}
						alt={d.collection_name}
					/>
				),
				maxWidth: 50,
			},
			{
				Header: 'Name',
				accessor: d =>  (
					<div className='collectionName'>
						{
							d.is_new && (
								<span className='newLabel'>
									New
								</span>
							)
						}
						{clipCollectionName(d.collection_name)}
					</div>
				),
				width: 200,
			},
			{
				Header: 'Mints',
				accessor: 'count',
				minWidth: 100,
			},
			{
				Header: 'Unique Minters',
				accessor: 'unique_count',
				minWidth: 100,
			},
			{
				Header: 'New Minters',
				accessor: 'new_minters',
				minWidth: 100,
			}
		],
		[]
	);

	const renderSales = () => {
		return (
			<div>
				<div className='TrackerSectionHeader'>
					<h1>
						Sales (Last Hour)
					</h1>
					<button 
						className='TrackerSwitchButton' 
						onClick={() => setView('mints')}
					>
						Switch to Mints
					</button>
				</div>
				<div>
					Updated <strong>{timeAgoFromString(salesData.last_updated)}</strong>
				</div>
				<Table
					columns={salesColumns}
					data={salesData.sales}
					onClickRow={(r) => window.open(r.collection_url, "_blank")}
				/>
			</div>
		)
	}

	const renderMints = () => {
		return (
			<div>
				<div className='TrackerSectionHeader'>
					<h1>
						Mints (Last Hour)
					</h1>
					<button 
						className='TrackerSwitchButton' 
						onClick={() => setView('sales')}
					>
						Switch to Sales
					</button>
				</div>
				<div>
					Updated <strong>{timeAgoFromString(mintsData.last_updated)}</strong>
				</div>
				<Table
					columns={mintColumns}
					data={mintsData.mints}
					onClickRow={(r) => window.open(r.collection_url, "_blank")}
				/>
			</div>
		)
	}

	if (isSalesLoading || isMintsLoading || !salesData || !mintsData) {
		return null;
	}

	return (
		<div className='TrackerContent'>
			{
				view === 'sales' ? renderSales() : renderMints()
			}
			<div className='Disclaimer'>
				The information provided on this website is for general informational purposes only. The user 
				assumes all responsibility and risk for the use of this website. We make no representations or 
				warranties of any kind, express or implied about the completeness, accuracy, reliability, suitability
				or availability of any information. Under no circumstances shall we have any liability to you for any loss
				or damage of any kind incurred as a result of the use of the site or reliance of any information 
				provided on the site.
			</div>
			<div>
        © 2021 digirocks.io
      </div>
		</div>
	);
}

export default MarketTracker;
