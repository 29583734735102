import React, { useState, useEffect } from 'react';
import { max_rocks } from './constants';
import axios from 'axios';
import './RemainingRocks.css'

const RemainingRocks = () => {
  const [purchased, setPurchased] = useState(0);

  useEffect(() => {
    axios.get(
      "https://niftyriver.herokuapp.com/api/core/digirocks-minted/"
    ).then(({data}) => {
        setPurchased(data.minted);
      }
    );
  }, []);

  const completed = Math.round((purchased / max_rocks) * 100);

	return (
    <div style={{marginBottom: '50px', marginTop: '50px'}}>
  		<h2 className="Subheader">
        Price
      </h2>
      <div className='Text'>
        The price of a DigiRock increases based on supply:
        <div className='PricingRow'>
          <div className='PricingBucket' style={{width: '13%'}}>
            <div className='PricingBucketRange'>1 - 500</div>
            <div className='PricingBucketPrice'>Ξ 0.015</div>
          </div>
          <div className='PricingBucket' style={{width: '24%'}}>
            <div className='PricingBucketRange'>501 - 1500</div>
            <div className='PricingBucketPrice'>Ξ 0.03</div>
          </div>
          <div className='PricingBucket' style={{width: '48%'}}>
            <div className='PricingBucketRange'>1500 - 3500</div>
            <div className='PricingBucketPrice'>Ξ 0.06</div>
          </div>
          <div className='PricingBucket' style={{width: '15%'}}>
            <div className='PricingBucketRange'>3501 - 4000</div>
            <div className='PricingBucketPrice'>Ξ 0.12</div>
          </div>
        </div>
        <div className="containerStyles">
          <div className="fillerStyles" style={{width: `${completed}%`}}>
            <span className="labelStyles">{purchased}/{max_rocks}</span>
          </div>
        </div>
      </div>
    </div>
	);
}

export default RemainingRocks;
